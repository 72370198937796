/* Add a 3D effect to the cards */
.card {
    /* width: 100%; */
    height: 190px;
    padding: 10px;
    background: #ffffff; /* Card background color */
   
  
    /* @media (min-width: 600px) {
      width: 250px;
    }
   */
    /* 3D effect on hover */
    transition: transform 0.3s, box-shadow 0.3s;
    transform: perspective(1000px) rotateY(0deg);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .card:hover {
    transform: perspective(1000px) rotateY(5deg);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  }

 
  
/* Add a 3D effect to .card2 */
.card2 {
    /* width: 100%; */
    height: 320px;
    padding: 10px;
    background: #ffffff; /* Card background color */
  
    /* 3D effect on hover */
    transition: transform 0.3s, box-shadow 0.3s;
    transform: perspective(1000px) rotateY(0deg);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  .cardAction1 {
    height: 350px;
    padding: 10px;
    background: #ffffff; /* Card background color */
  }
  .card3 {
    /* width: 90%; */
    height: 400px;
    padding: 10px;
    background: #ffffff; /* Card background color */
  
    /* 3D effect on hover */
    transition: transform 0.3s, box-shadow 0.3s;
    transform: perspective(1000px) rotateY(0deg);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .card4 {
    /* width: 80%; */
    height: 350px;
    padding: 10px;
    background: #ffffff; /* Card background color */
  
    /* 3D effect on hover */
    transition: transform 0.3s, box-shadow 0.3s;
    transform: perspective(1000px) rotateY(0deg);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  /* .card2:hover {
    transform: perspective(1000px) rotateY(5deg);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  }
   */
/* Add your custom styling for responsiveness */
@media (max-width: 600px) {
  .responsive-chip-stack {
    margin-bottom: 8px; /* Adjust the spacing for smaller screens */
  }
}

