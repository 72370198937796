.map-container {
    height: 100% !important;
    /* Fill parent */
    width: 100% !important;
    position: relative !important;
    z-index: 1;
}

.map-wrapper {
    position: relative;
    flex: 1;
    display: flex;
    /* Ensure children can use flex properties */
}

.leaflet-container {
    height: 100% !important;
    width: 100% !important;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 1;
}

/* Ensure proper z-index for map elements */
.leaflet-pane {
    z-index: 400 !important;
}

.leaflet-tile-pane {
    z-index: 200 !important;
}

.leaflet-overlay-pane {
    z-index: 400 !important;
}

.leaflet-marker-pane {
    z-index: 600 !important;
}

.leaflet-tooltip-pane {
    z-index: 650 !important;
}

.leaflet-popup-pane {
    z-index: 700 !important;
}

.leaflet-control {
    z-index: 800 !important;
}

/* Make controls more compact */
.leaflet-control-zoom {
    width: 26px !important;
    height: 26px !important;
}

.leaflet-control-zoom a {
    width: 26px !important;
    height: 26px !important;
    line-height: 26px !important;
    font-size: 14px !important;
}

/* Make tooltips more compact */
.custom-tooltip {
    padding: 4px 8px !important;
    font-size: 12px !important;
    max-width: 200px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    z-index: 1000 !important;
}

.tooltip-content {
    white-space: nowrap;
}

.custom-marker {
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.custom-marker.green {
    background-color: green;
}

.custom-marker.yellow {
    background-color: yellow;
}

.custom-marker.red {
    background-color: red;
}

.custom-marker.gray {
    background-color: gray;
}

.custom-marker.gray-red-dot {
    background-color: gray;
    border: 3px solid red;
}